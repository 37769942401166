import React, { useContext } from "react";
import { Navbar, Nav, Button, Container, Form } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaSearch,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import logo from "../images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../constants/tokenData";
import { UserContext } from "../constants/userContext";

const CustomNavbar: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const { adminRights, fullName } = useContext(UserContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/";
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const shouldHideElements = currentPath.includes("item-search");

  const handleSellItemClick = () => {
    navigate("/my-items-on-sale");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const navigate = useNavigate();

  const handleHomePage = () => {
    navigate("/");
  };

  const handleLookingFor = () => {
    navigate("/looking-for");
  };

  const handleSearchNavigation = () => {
    navigate("/item-search");
  };

  return (
    <>
      {/* Top Navbar for Help Center, Order Tracking, Language, and Currency */}
      <Navbar bg="light" variant="light" className="py-0 border-bottom">
        <Container>
          <Nav className="me-auto">
            <Nav.Link
              href="https://www.facebook.com/groups/amd.bike"
              target="blank"
              className="text-muted small"
            >
              <FaFacebookF style={{ color: "#1877F2" }} />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/amd.bike/"
              className="text-muted small"
            >
              <FaInstagram style={{ color: "#E4405F" }} />
            </Nav.Link>
            <Nav.Link
              href="https://wa.me/+254774310747"
              target="blank"
              className="text-muted small"
            >
              <FaWhatsapp style={{ color: "#25D366" }} />
            </Nav.Link>
            <Nav.Link
              href="https://www.youtube.com/@AMD.bike254"
              target="blank"
              className="text-muted small"
            >
              <FaYoutube style={{ color: "#FF0000" }} />
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            <Nav className="ml-3" style={{ marginRight: "5px" }}>
              {fullName}
            </Nav>
            {!isAuthenticated ? (
              <>
                <span onClick={handleLogin} className="ms-1">
                  Login / Register
                </span>
              </>
            ) : (
              <>
                {isAuthenticated && (
                  <>
                    <div className="logout" onClick={handleLogout}>
                      <span>Logout</span>
                    </div>
                  </>
                )}
              </>
            )}
          </Nav>
        </Container>
      </Navbar>

      {/* Main Navbar with Logo, Search Bar, and User Actions */}
      <Navbar bg="light" className="py-3 border-bottom">
        <Container>
          <Navbar.Brand onClick={handleHomePage} style={{ cursor: "pointer" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxHeight: "55px" }}
              className="img-fluid"
            />
          </Navbar.Brand>

          {shouldHideElements ? (
            <>
              <Form>
                <Button disabled size="sm" variant="default" className="ms-2">
                  SEARCH ITEM
                  <FaSearch className="me-2" style={{ marginLeft: "5px" }} />
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Form>
                <Button
                  size="sm"
                  onClick={handleSearchNavigation}
                  variant="danger"
                  className="ms-2"
                >
                  SEARCH ITEM
                  <FaSearch className="me-2" style={{ marginLeft: "5px" }} />
                </Button>
              </Form>
            </>
          )}
        </Container>
      </Navbar>

      {/* Bottom Navbar for Categories and Navigation Links */}
      <Navbar bg="light" expand="lg" className="py-2">
        <Container>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="me-auto align-items-center">
              <Nav.Link href="/" style={{ color: "black" }}>
                Home
              </Nav.Link>
              <Nav.Link href="/bikes-on-sale" style={{ color: "black" }}>
                Bikes on sale
              </Nav.Link>
              <Nav.Link href="/gears-on-sale" style={{ color: "black" }}>
                Riding Gear on sale
              </Nav.Link>
              <Nav.Link href="/parts-on-sale" style={{ color: "black" }}>
                Parts on sale
              </Nav.Link>
              <Nav.Link href="/vendors-list" style={{ color: "black" }}>
                Vendors
              </Nav.Link>
              {adminRights === true && (
                <Nav.Link href="/admin-dashboard" style={{ color: "black" }}>
                  Admin
                </Nav.Link>
              )}

              {isAuthenticated ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Nav.Link onClick={handleLookingFor}>
                    <div className="buy-badge">I’m looking for</div>
                  </Nav.Link>
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Nav>
                    <Nav.Link onClick={handleLogin}>
                      <div className="buy-badge-logged-out">
                        I’m looking for
                      </div>
                    </Nav.Link>
                  </Nav>
                </div>
              )}

              {isAuthenticated ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Nav.Link onClick={handleSellItemClick}>
                    <div className="sell-badge">My business account</div>
                  </Nav.Link>
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Nav>
                    <Nav.Link onClick={handleLogin}>
                      <div className="buy-badge-logged-out">Sell an item</div>
                    </Nav.Link>
                  </Nav>
                </div>
              )}
              <Nav.Link href="/system-updates">
                <div className="system-updates">System updates</div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
