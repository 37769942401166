import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import FetchUserDetails from "./fetchUser";
import GetCategory from "../vendorNumbers/get-category";
import FetchPostViews from "../components/fetchViews";
import FetchBusinessCompleteness from "../components/fetchBusiness";
import FetchImageCompleteness from "../components/fetchImageCompleteness";
import { useNavigate } from "react-router-dom";
import FetchContactViews from "../components/fetchContactClicks";

interface ProductsData {
  availability: boolean;
  itemCategory: number;
  id: number;
  productName: string;
  createdAt: string;
  priceOfItem: number;
  userId: number;
}

const FetchItemsPosted: React.FC = () => {
  const [recentProducts, setRecentProducts] = useState<ProductsData[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-overview/get-recent-items`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setRecentProducts(data);
        }
      } catch (error) {
        toast.error(`Error fetching details: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewMoreItems = () => {
    navigate("/all-posted-items");
  };

  return (
    <>
      <div className="table-responsive">
        {loading ? (
          <>
            Loading new products table <Spinner animation="border" size="sm" />
          </>
        ) : (
          <Container
            fluid
            style={{
              borderRadius: "20px",
              padding: "30px",
            }}
          >
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Posted items
            </h5>
            <h6>Last 10 items posted and business details</h6>
            <hr></hr>
            <Table
              hover
              responsive
              style={{
                backgroundColor: "transparent",
                borderCollapse: "separate",
              }}
            >
              <thead style={{ backgroundColor: "#FFF5EE" }}>
                <tr>
                  <th>#ProductID</th>
                  <th>Date</th>
                  <th>Item</th>
                  <th>Category</th>
                  <th>Availability</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Details</th>
                  <th>Images</th>
                  <th style={{ textAlign: "center" }}>Views</th>
                  <th style={{ textAlign: "center" }}>ContactClicks</th>
                </tr>
              </thead>
              <tbody>
                {recentProducts && recentProducts.length > 0 ? (
                  recentProducts.map((product) => (
                    <tr key={product.id}>
                      <td>#{product.id}</td>
                      <td>
                        {new Date(product.createdAt).toLocaleDateString()}
                      </td>
                      <td>{product.productName}</td>
                      <td>
                        <GetCategory categoryId={product.itemCategory} />
                      </td>
                      <td>
                        {product.availability === true ? "Available" : "Sold"}
                      </td>
                      <td>{product.priceOfItem.toLocaleString()}</td>
                      <td>
                        <FetchUserDetails userId={product.userId} />
                      </td>
                      <td>
                        <FetchBusinessCompleteness postId={product.id} />
                      </td>
                      <td>
                        <FetchImageCompleteness postId={product.id} />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <FetchPostViews postId={product.id} />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <FetchContactViews postId={product.id} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: "center" }}>
                      No products found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <br />
            <br />
            <Button size="sm" variant="danger" onClick={handleViewMoreItems}>
              Viem more items
            </Button>
          </Container>
        )}
      </div>
    </>
  );
};

export default FetchItemsPosted;
