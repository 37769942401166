import { FormEvent, useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { BsImageFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../auth/authProvider";
import VendorLogo from "../images/vendorImage.png";
import TimeDisplayProps from "../components/timeFormat";
import CustomNavbar from "../headerpages/header";

interface userBusiness {
  createdAt: string;
  id: number;
  itemsSold: number;
  businessContacts: string;
  businessEmail: string;
  businessLocation: string;
  returnPolicy: boolean;
  returnDays: number;
  businessName: string;
  businessNature: string;
  businessDescription: string;
}

const BusinessProfile = () => {
  const [logo, setLogo] = useState<string | null>(null);
  const [businessData, setBusinessData] = useState<userBusiness | null>(null);
  const [voidBusinessProfile, setVoidBusinessProfile] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [businessContacts, setBusinessContacts] = useState("254 ");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [returnPolicy, setReturnPolicy] = useState(false);
  const [returnDays, setReturnDays] = useState(0);
  const [businessDescription, setBusinessDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedBusinessName, setEditedBusinessName] = useState("");
  const [editedBusinessContacts, setEditedBusinessContacts] = useState("");
  const [editedBusinessEmail, setEditedBusinessEmail] = useState("");
  const [editedBusinessNature, setEditedBusinessNature] = useState("");
  const [editedBusinessLocation, setEditedBusinessLocation] = useState("");
  const [editedReturnPolicy, setEditedReturnPolicy] = useState(false);
  const [editedReturnDays, setEditedReturnDays] = useState(0);
  const [editedBusinessDescription, setEditedBusinessDescription] =
    useState("");

  const navigate = useNavigate();

  // Modal for user profile creation
  const [profileModal, setProfileModal] = useState(false);
  const showProfileModal = () => setProfileModal(true);
  const CloseProfileModal = () => setProfileModal(false);

  // Modal for profile edit
  const [editProfile, setEditProfile] = useState(false);
  const showEditProdile = () => {
    if (businessData) {
      // Set initial values from businessData when the modal is shown
      setEditedBusinessName(businessData.businessName);
      setEditedBusinessContacts(businessData.businessContacts);
      setEditedBusinessEmail(businessData.businessEmail);
      setEditedBusinessNature(businessData.businessNature);
      setEditedBusinessLocation(businessData.businessLocation);
      setEditedReturnPolicy(businessData.returnPolicy);
      setEditedReturnDays(businessData.returnDays);
      setEditedBusinessDescription(businessData.businessDescription);
    }
    setEditProfile(true);
  };
  const hideEditProfile = () => {
    setEditedBusinessName("");
    setEditedBusinessContacts("");
    setEditedBusinessEmail("");
    setEditedBusinessNature("");
    setEditedBusinessLocation("");
    setEditedReturnPolicy(false);
    setEditedReturnDays(0);
    setEditedBusinessDescription("");
    setEditProfile(false);
  };

  // Modal for logo change
  const [changeLogo, setChangeLogo] = useState(false);
  const showEditLogo = () => setChangeLogo(true);
  const hideEditLogo = () => setChangeLogo(false);

  // Modal for profile delete
  const [profileDelete, setProfileDelete] = useState(false);
  const showProfileDelete = () => setProfileDelete(true);
  const hideProfileDelete = () => setProfileDelete(false);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/user/get-business-details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response?.ok) {
          const businessDetails = await response?.json();
          setBusinessData(businessDetails);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchBusinessData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        // Fetch logo
        const getLogo = await fetch(
          `${BASE_API_URL}/product-images/get-business-logo`,
          { method: "GET", headers }
        );

        if (getLogo.ok) {
          const logoData = await getLogo.text();
          setLogo(logoData);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchData();
  }, []);

  const handleCreateProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const businessProfile = {
        businessName,
        businessContacts,
        businessEmail,
        businessNature,
        businessLocation,
        returnPolicy,
        returnDays,
        businessDescription,
      };

      const response = await fetch(
        `${BASE_API_URL}/user/create-business-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(businessProfile),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Business profile created!";
        toast.success(successMessage);
        CloseProfileModal();
        setBusinessData(responseData);
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const businessProfile = {
        businessName: editedBusinessName,
        businessContacts: editedBusinessContacts,
        businessEmail: editedBusinessEmail,
        businessNature: editedBusinessNature,
        businessLocation: editedBusinessLocation,
        returnPolicy: editedReturnPolicy,
        returnDays: editedReturnDays,
        businessDescription: editedBusinessDescription,
      };

      const response = await fetch(
        `${BASE_API_URL}/user/edit-business-details`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(businessProfile),
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = "Business profile edited!";
        toast.success(successMessage);
        CloseProfileModal();
        setBusinessData(responseData);
        hideEditProfile();
      } else {
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUploadlogo = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      const fileInput = e.currentTarget[0] as HTMLInputElement;
      const file = fileInput.files && fileInput.files[0];

      if (!file) {
        console.error("No file selected.");
        setIsSubmitting(false);
        return;
      }

      formData.append("file", file);

      const response = await fetch(
        `${BASE_API_URL}/product-images/upload-logo`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );

      const responseData = await response.json();
      const { statusCode, message } = responseData;

      if (response.ok) {
        const successMessage = `Business logo uploaded successfully`;
        toast.success(successMessage);

        hideEditLogo();
        setIsSubmitting(false);

        window.location.reload();

        // Reset the file input after successful submission
        e.currentTarget.reset();
      } else {
        const errorMessage = `${statusCode} -  ${message}`;
        toast.error(errorMessage);
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVoidBusiness = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const voidbusiness = { voidBusinessProfile };

      const response = await fetch(
        `${BASE_API_URL}/user/void-business-details`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(voidbusiness),
        }
      );

      if (response.ok) {
        toast.success("Profile has been deleted sucessfully!");
        hideProfileDelete();
        navigate("/");
      } else {
        const error = await response.json();
        toast.error(error.message);
      }
    } catch (error) {
      toast.error(`An error occured: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />
        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <Container style={{ color: "gray" }}>
            Home | Profile | My business Profile
          </Container>
        </Container>

        <Container className="margin-top">
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <div className="box-bordered">
                      <h5 className="title">
                        <strong>Business logo</strong>
                      </h5>
                      <p>Business logo is important for business recognition</p>
                      <hr />
                      {businessData ? (
                        <>
                          {logo ? (
                            <img
                              src={logo}
                              alt="Business Logo"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={VendorLogo}
                              alt="Business Logo"
                              className="img-fluid"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <img
                            src={VendorLogo}
                            alt="Business Logo"
                            className="img-fluid"
                          />
                        </>
                      )}
                      <br />
                      <br />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="box-bordered">
                      <h5 className="title">
                        <strong>Business profile</strong>
                      </h5>
                      <p>
                        Provide details about your business. Make your
                        information as attractive and compelling as possible.
                      </p>
                      <hr />
                      {businessData ? (
                        <div>
                          <b>Business name:</b> {businessData.businessName}
                          <br />
                          <b>Business contact:</b>{" "}
                          {businessData.businessContacts}
                          <br />
                          <b>Business email:</b>{" "}
                          {businessData.businessEmail || "No email"}
                          <br />
                          <b>Business nature:</b> {businessData.businessNature}
                          <br />
                          <b>Business location:</b>{" "}
                          {businessData.businessLocation}
                          <br />
                          <br />
                          Return Policy:{" "}
                          <b>{businessData.returnDays ? "Yes" : "No"}</b>
                          <br />
                          Return days: <b>
                            {businessData.returnDays || 0}
                          </b>{" "}
                          days
                          <br />
                          <br />
                          <b>Business description:</b>{" "}
                          {businessData.businessDescription ||
                            "No business description found"}
                          <p className="title">
                            <br />
                            <br />
                            <br />
                            <Row>
                              <Col md={12} style={{ color: "gray" }}>
                                <small>
                                  Registered Date:{" "}
                                  <TimeDisplayProps
                                    timeString={businessData.createdAt}
                                  />
                                </small>
                              </Col>
                            </Row>
                          </p>
                        </div>
                      ) : (
                        <div>
                          No business profile found! Click{" "}
                          <b
                            onClick={showProfileModal}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            here
                          </b>{" "}
                          to create a business profile.
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <div className="box-bordered right">
                  <Col md={12}>
                    {businessData ? (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">Business logo</Tooltip>
                          }
                        >
                          <Button
                            onClick={showEditLogo}
                            size="sm"
                            className="margin-space"
                          >
                            <BsImageFill className="button-icon" /> Change logo
                          </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip">Edit profile</Tooltip>}
                        >
                          <Button
                            onClick={showEditProdile}
                            size="sm"
                            className="margin-space"
                          >
                            <BsImageFill className="button-icon" /> Edit profile
                          </Button>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <>
                        <Button disabled size="sm" className="margin-space">
                          <FaLock className="button-icon" /> Business logo
                        </Button>

                        <Button disabled size="sm" className="margin-space">
                          <FaLock className="button-icon" /> Edit business
                        </Button>
                      </>
                    )}

                    {businessData ? (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">Delete business</Tooltip>
                          }
                        >
                          <Button
                            onClick={showProfileDelete}
                            size="sm"
                            variant="danger"
                            className="margin-space"
                          >
                            <BsImageFill className="button-icon" /> Delete
                            business
                          </Button>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Button
                        size="sm"
                        disabled
                        variant="danger"
                        className="margin-space delete-button"
                      >
                        <FaLock className="button-icon" /> Delete business
                      </Button>
                    )}
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={profileModal} onHide={CloseProfileModal}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              <Form onSubmit={handleCreateProfile}>
                <Form.Group className="mb-4">
                  <Form.Label>Business name *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business contacts *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={businessContacts}
                    onChange={(e) => setBusinessContacts(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business email / Personal email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    value={businessEmail}
                    onChange={(e) => setBusinessEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business Nature</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    value={businessNature}
                    onChange={(e) => setBusinessNature(e.target.value)}
                  >
                    <option value="" disabled>
                      Select business nature
                    </option>
                    <option value="Online based">Online based</option>
                    <option value="Physical location">Physical location</option>
                    <option value="Hybrid">Hybrid</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business location</Form.Label>
                  <Form.Control
                    type="text"
                    value={businessLocation}
                    onChange={(e) => setBusinessLocation(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Return policy</Form.Label>
                  <Form.Check
                    type="checkbox"
                    checked={returnPolicy}
                    onChange={(e) => setReturnPolicy(e.target.checked)}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Return days <small>(If return policy is accepted)</small>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={returnDays}
                    onChange={(e) =>
                      setReturnDays(parseInt(e.target.value, 10))
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Business description <small>(Optional)</small>{" "}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="What makes your business to stand out ... . . 
                quality, experience, delivery, customer reviews, location, honesty, 
                customer care, years of experience, vast knowledge of products ... . e.t.c"
                    rows={10}
                    value={businessDescription}
                    onChange={(e) => setBusinessDescription(e.target.value)}
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={CloseProfileModal}
                    disabled={isSubmitting}
                  >
                    Close modal
                  </Button>
                  <Button variant="primary" type="submit" size="sm">
                    {isSubmitting === true
                      ? "Creating profile"
                      : "Create profile"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={editProfile} onHide={hideEditProfile}>
          <Modal.Header closeButton className="strong">
            Edit business profile
          </Modal.Header>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              {businessData ? (
                <div>
                  <Form onSubmit={handleEditProfile}>
                    <Form.Group className="mb-4">
                      <Form.Label>Business name *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={editedBusinessName}
                        onChange={(e) => setEditedBusinessName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business contacts *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={editedBusinessContacts}
                        onChange={(e) =>
                          setEditedBusinessContacts(e.target.value)
                        }
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business email / Personal email</Form.Label>
                      <Form.Control
                        type="email"
                        required
                        value={editedBusinessEmail}
                        onChange={(e) => setEditedBusinessEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business Nature</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        value={editedBusinessNature}
                        onChange={(e) =>
                          setEditedBusinessNature(e.target.value)
                        }
                      >
                        <option value="" disabled>
                          Select business nature
                        </option>
                        <option value="Online based">Online based</option>
                        <option value="Physical location">
                          Physical location
                        </option>
                        <option value="Hybrid">Hybrid</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Business location</Form.Label>
                      <Form.Control
                        type="text"
                        value={editedBusinessLocation}
                        onChange={(e) =>
                          setEditedBusinessLocation(e.target.value)
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Return policy</Form.Label>
                      <Form.Check
                        type="checkbox"
                        checked={editedReturnPolicy}
                        onChange={(e) =>
                          setEditedReturnPolicy(e.target.checked)
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Return days{" "}
                        <small>(If return policy is accepted)</small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={editedReturnDays}
                        onChange={(e) =>
                          setEditedReturnDays(parseInt(e.target.value, 10))
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Business description <small>(Optional)</small>{" "}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="What makes your business to stand out ... . . 
                quality, experience, delivery, customer reviews, location, honesty, 
                customer care, years of experience, vast knowledge of products ... . e.t.c"
                        rows={10}
                        value={editedBusinessDescription}
                        onChange={(e) =>
                          setEditedBusinessDescription(e.target.value)
                        }
                      />
                    </Form.Group>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={CloseProfileModal}
                        disabled={isSubmitting}
                      >
                        Close modal
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        size="sm"
                        disabled={isSubmitting}
                      >
                        {isSubmitting === true
                          ? "Editing profile"
                          : "Create profile"}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </div>
              ) : (
                <div>
                  No business profile found! Click{" "}
                  <b
                    onClick={showProfileModal}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    here
                  </b>{" "}
                  to create a business profile.
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={changeLogo} onHide={hideEditLogo}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              <Form onSubmit={handleUploadlogo}>
                <Form.Group className="mb-4">
                  <Form.Label>Add images for item</Form.Label>
                  <Form.Control
                    required
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={hideEditLogo}
                    disabled={isSubmitting}
                  >
                    Close modal
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    size="sm"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Updating logo" : "Change logo"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={profileDelete} onHide={hideProfileDelete}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="border-line shadow-lg p-3 mb-5 bg-white rounded">
              Delete business profile
              <br />
              <br />
              Are you sure you want to delete your profile? This action is
              irreversible. However, you can reactivate your profile by writing
              to the <b>admin.</b>
              <br />
              Please note that all your sale posts will be deactivated and
              account archieved.
              <hr />
              <br />
              <Form onSubmit={handleVoidBusiness}>
                <Form.Group className="mb-4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Check
                      style={{ transform: "scale(1.5)", marginRight: "10px" }}
                      type="checkbox"
                      checked={voidBusinessProfile}
                      onChange={(e) => setVoidBusinessProfile(e.target.checked)}
                    />
                    <span style={{ color: "red" }}>I confirm deletion</span>
                  </div>
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={hideProfileDelete}
                    disabled={isSubmitting}
                  >
                    Close modal
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    size="sm"
                    disabled={isSubmitting}
                    className="btn-delete"
                  >
                    {isSubmitting === true
                      ? "Deleting profile"
                      : "Delete profile"}
                  </Button>
                </Modal.Footer>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default BusinessProfile;
