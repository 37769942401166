import React from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import comingSoon from "../images/comingSoon.png";
import { Container } from "react-bootstrap";
import CustomNavbar from "../headerpages/header";
import AuthProvider from "../auth/authProvider";

const LookingFor: React.FC = () => {
  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          style={{ backgroundColor: "#F5F0F0", padding: "40px" }}
          fluid
        >
          <Container style={{ color: "GrayText" }}>
            Home | Looking for
          </Container>
        </Container>
        <div className="spacing"></div>
        <Container style={{ textAlign: "center" }}>
          <h5 className="text-danger" style={{ fontWeight: "bold" }}>
            My item search
          </h5>
          <br></br>
          <img src={comingSoon} alt="Coming soon" className="img-fluid" />
          <div className="spacing"></div>
        </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default LookingFor;
