// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  height: 70px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
  position: relative;
  z-index: 1000;
}

.nav-link {
  margin-right: 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #fa7f2b !important;
}

@media (max-width: 768px) {
  .navbar-collapse {
    background-color: #f8f9fa;
    border-radius: 10px;
  }

  .nav-link {
    margin-right: 0;
    margin: 10px 0;
    text-align: center;
  }
}

.quicklinks-card {
  text-align: left;
}

.quicklinks-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.quicklinks-item {
  margin: 15px 0;
  color: #333;
}

.quicklinks-link {
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.quicklinks-link:hover {
  color: #0056b3;
}

.logout {
  cursor: pointer;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/navBarCss.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,qDAAqD;EACrD,aAAa;EACb,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;EACpB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ","sourcesContent":[".navbar {\n  height: 70px;\n  border-radius: 5px;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;\n  padding: 10px;\n  position: relative;\n  z-index: 1000;\n}\n\n.nav-link {\n  margin-right: 10px;\n  text-decoration: none;\n  transition: color 0.3s ease;\n}\n\n.nav-link:hover {\n  color: #fa7f2b !important;\n}\n\n@media (max-width: 768px) {\n  .navbar-collapse {\n    background-color: #f8f9fa;\n    border-radius: 10px;\n  }\n\n  .nav-link {\n    margin-right: 0;\n    margin: 10px 0;\n    text-align: center;\n  }\n}\n\n.quicklinks-card {\n  text-align: left;\n}\n\n.quicklinks-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.quicklinks-item {\n  margin: 15px 0;\n  color: #333;\n}\n\n.quicklinks-link {\n  color: #007bff;\n  cursor: pointer;\n  transition: color 0.3s ease;\n}\n\n.quicklinks-link:hover {\n  color: #0056b3;\n}\n\n.logout {\n  cursor: pointer;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
