import { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";

const UserProgress = () => {
  const [dataCount, setDataCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-data-count/get-users-progress`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setDataCount(data);
        }
      } catch (error) {
        toast.error(`Error fetching details: ${error}`);
      }
    };

    fetchData();
  }, []);

  return dataCount;
};

export default UserProgress;
