import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  Envelope,
  Telephone,
  Box,
  InfoCircle,
  FileText,
} from "react-bootstrap-icons";

const Footer: React.FC = () => {
  return (
    <footer
      className="footer pt-5"
      style={{
        backgroundColor: "#F5F0F0",
        color: "black",
        marginTop: "40px",
      }}
    >
      <Container>
        <Row>
          {/* Contact Us */}
          <Col md={3} style={{ textAlign: "left" }}>
            <h5 style={{ fontWeight: "bold" }}>Contact Us</h5>
            <p>
              <Envelope /> email:{" "}
              <a
                href="mailto:admin@amd.bike"
                style={{ textDecoration: "none", color: "GrayText" }}
              >
                admin@amd.bike
              </a>
            </p>
            <p>
              <Telephone /> phone:{" "}
              <a
                href="tel:+254734690591"
                style={{ textDecoration: "none", color: "GrayText" }}
              >
                (+254) 734-690-591
              </a>
            </p>
            <br />
            <p>Nairobi, Kenya</p>
            <div className="spacing"></div>
            <div className="spacing"></div>
          </Col>

          {/* Products */}
          <Col md={3} className="mb-4" style={{ textAlign: "left" }}>
            <h5 style={{ fontWeight: "bold" }}>Products</h5>
            <ul className="list-unstyled">
              <li>
                <Box />{" "}
                <a
                  href="#prices-drop"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Sport Bikes
                </a>
              </li>
              <li>
                <Box />{" "}
                <a
                  href="#new-products"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Riding Gear
                </a>
              </li>
              <li>
                <Box />{" "}
                <a
                  href="#best-sales"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Bike Parts
                </a>
              </li>
            </ul>
          </Col>

          {/* Our Company */}
          <Col md={3} className="mb-4" style={{ textAlign: "left" }}>
            <h5 style={{ fontWeight: "bold" }}>Our Company</h5>
            <ul className="list-unstyled">
              <li>
                <FileText />{" "}
                <a
                  href="#terms-and-conditions"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Terms And Conditions Of Use
                </a>
              </li>
              <li>
                <InfoCircle />{" "}
                <a
                  href="#about-us"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  About Us
                </a>
              </li>
              <li>
                <InfoCircle />{" "}
                <a
                  href="#login"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Login
                </a>
              </li>
            </ul>
          </Col>

          {/* Subscribe to Newsletter */}
          <Col md={3} className="mb-4">
            <h5 style={{ fontWeight: "bold" }}>Subscribe To Newsletter</h5>
            <p>
              Subscribe to our newsletter to get latest updates on items posted
              for sale!
            </p>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Your Email Address" />
              </Form.Group>
              <Button variant="danger" type="submit" className="my-2 w-100">
                SUBSCRIBE
              </Button>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="I agree to the terms and conditions and the privacy policy"
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="footer-bottom text-center pt-3"
        style={{ borderTop: "1px solid #ddd", marginTop: "20px" }}
      >
        <p style={{ margin: 0 }}>
          &copy; {new Date().getFullYear()} Developed and maintained by Videll
          254711414182.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
