import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const FetchDataCountDeletedItems: React.FC = () => {
  const [dataCount, setDdataCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-data-count/get-deleted-items-count`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setDdataCount(data);
        }
      } catch (error) {
        toast.error(`Error fetching details:, ${error}`);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {dataCount !== null ? (
        dataCount
      ) : (
        <Spinner animation="border" size="sm" />
      )}
    </>
  );
};

export default FetchDataCountDeletedItems;
