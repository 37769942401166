import React from "react";
import moment from "moment-timezone";

interface TimeDisplayProps {
  timeString: string;
}

const TimeDisplay: React.FC<TimeDisplayProps> = ({ timeString }) => {
  const time = moment.parseZone(timeString);

  if (time.isValid()) {
    return <span>{time.tz("Africa/Nairobi").format("DD/MM/YYYY")}</span>;
  }

  return <span>Invalid!</span>;
};

export default TimeDisplay;
