import React, { ChangeEvent, FormEvent, useState } from "react";
import Footer from "../headerpages/footer";
import CustomNavbar from "../headerpages/header";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  FormSelect,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Search from "../images/search.png";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { FaSearch } from "react-icons/fa";
import { Eye } from "react-bootstrap-icons";
import FetchImage from "../components/fetchImage";
import formatNumberWithCommas from "../components/numberFormat";
import { useNavigate } from "react-router-dom";

interface SearchResults {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
  createdAt: string;
}

const SearchItem: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<SearchResults[]>([]);
  const [itemName, setItemName] = useState("");
  const [price, setPrice] = useState("");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");

  const navigate = useNavigate();

  const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const DataQuery = {
        category: selectedCategory,
        itemName: itemName,
        price: Number(price),
        year: Number(year),
        mileage: Number(mileage),
      };

      const baseUrl = `${BASE_API_URL}`;
      const methodGet = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(DataQuery),
      };

      const response = await fetch(
        `${baseUrl}/search/search-results`,
        methodGet
      );

      const dataResponse = await response.json();

      if (response.ok) {
        setSearchResults(dataResponse);
      } else {
        const { statusCode, message } = dataResponse;
        const errorMessage = `${statusCode} - ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    }
  };

  const handleCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(Number(e.target.value));
  };

  const handleViewClick = (id: number) => {
    navigate(`/item-details/${id}`);
  };

  return (
    <div className="body">
      <CustomNavbar />
      <Container fluid style={{ backgroundColor: "#F5F0F0", padding: "40px" }}>
        <Container style={{ color: "GrayText" }}>Home | Search</Container>
      </Container>

      <div className="spacing"></div>

      <Container style={{ textAlign: "center" }}>
        <h5 className="text-danger" style={{ fontWeight: "bold" }}>
          Search for an item
        </h5>
        <br />
        <Form onSubmit={handleSearch}>
          <div className="row g-2">
            <div className="col-md-3">
              <FormSelect
                required
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="">Select a category</option>
                <option value={1}>Sport Bikes</option>
                <option value={2}>Riding gear</option>
                <option value={3}>Bike parts</option>
              </FormSelect>
            </div>

            <div className="col-md-3">
              <FormControl
                type="text"
                placeholder="Item name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
            </div>

            <div className="col-md-3">
              <FormControl
                type="number"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            {selectedCategory === 1 && (
              <>
                <div className="col-md-3">
                  <FormControl
                    type="number"
                    placeholder="Year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <FormControl
                    type="number"
                    placeholder="Mileage"
                    value={mileage}
                    onChange={(e) => setMileage(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
          <br />
          <div className="row mt-3">
            <div className="col-md-3">
              <Button
                type="submit"
                size="sm"
                variant="danger"
                className="w-100"
              >
                SEARCH SYSTEM
                <FaSearch className="me-2" style={{ marginLeft: "5px" }} />
              </Button>
            </div>
          </div>
        </Form>

        <div className="spacing"></div>
        <br />
        <br />
        {searchResults.length > 0 ? (
          <>
            <Container className="py-4">
              <h5 style={{ fontWeight: "bold" }}>Sport Bikes on sale</h5>
              <p>
                Explore a variety of bikes sold by our registered vendors below.
                Take advantage of these great deals!
              </p>
              <Row>
                {searchResults.map((item, index) => (
                  <Col key={index} md={3} style={{ textAlign: "center" }}>
                    <Card className="mb-4">
                      <FetchImage postId={item.id} />
                      <Card.Body>
                        <Card.Title>{item.productName}</Card.Title>
                        <p>
                          <span className="price">
                            Ksh: {formatNumberWithCommas(item.priceOfItem)}
                          </span>
                        </p>
                        <Button
                          onClick={() => handleViewClick(item.id)}
                          variant="danger"
                          size="sm"
                        >
                          View more details <Eye />
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </>
        ) : (
          <>
            <br />
            <br />
            <img src={Search} alt="Search" className="img-fluid" />
          </>
        )}
        <br />
        <br />
        <br />
        <br />
      </Container>

      <Footer />
    </div>
  );
};

export default SearchItem;
