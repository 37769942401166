import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  PersonPlus,
  BoxArrowInRight,
  ExclamationCircle,
} from "react-bootstrap-icons";

const QuickLinksPage: React.FC = () => {
  const navigate = useNavigate();

  const handleRegistration = () => {
    navigate("/create-an-account");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Container>
      <div className="quicklinks-card">
        <ul className="quicklinks-list">
          <li className="quicklinks-item">
            <b>System Access Links</b>
            <small>
              <li className="quicklinks-item">
                1. Have an account?{" "}
                <b onClick={handleLogin} className="quicklinks-link">
                  <BoxArrowInRight /> Login
                </b>
              </li>

              <li className="quicklinks-item">
                2. New system user?{" "}
                <b onClick={handleRegistration} className="quicklinks-link">
                  <PersonPlus /> Register
                </b>
              </li>
              <li className="quicklinks-item">
                3. Trouble logging in?{" "}
                <b onClick={handleForgotPassword} className="quicklinks-link">
                  <ExclamationCircle /> Forgot password
                </b>
              </li>
            </small>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default QuickLinksPage;
