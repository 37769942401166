import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./commonPages/homePage";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cssFiles/bodyCss.css";
import "./cssFiles/headerCss.css";
import "./cssFiles/loginPage.css";
import "./cssFiles/navBarCss.css";
import "./cssFiles/product.css";
import "./cssFiles/searchBar.css";
import "./cssFiles/vendor.css";
import "./cssFiles/button.css";
import "./cssFiles/item.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./cssFiles/form.css";
import "./cssFiles/bodyTwo.css";
import "./cssFiles/registration.css";
import "./cssFiles/table.css";
import "./cssFiles/video.css";
import { ToastContainer } from "react-toastify";
import BikesOnSale from "./commonPages/bikesOnSale";
import Vendors from "./commonPages/vendors";
import VendorItems from "./vendorPages/vendorsPage";
import VendorItemDetails from "./vendorPages/vendor-item-detail";
import CustomerItems from "./commonPages/itemDetails";
import VendorAddImages from "./vendorPages/vendor-item-add-images";
import VendorAddItemDetails from "./vendorPages/vendor-item-add-details";
import GearsOnSale from "./commonPages/gearsOnSale";
import PartsOnSale from "./commonPages/partsOnSale";
import RegistrationPage from "./accessPages/registration";
import LoginPage from "./accessPages/login";
import AccountVerification from "./accessPages/accountActivation";
import ForgotPassword from "./accessPages/forgotPassword";
import PasswordReset from "./accessPages/passwordSetup";
import VendorProducts from "./commonPages/vendorProducts";
import Dashboard from "./admin/dashboard";
import { UserContext } from "./constants/userContext";
import usePageViews from "./google-analytics/google-analytics-hook";
import BusinessProfile from "./vendorPages/vendor-profile";
import SalesDashboard from "./vendorPages/salesDashboard";
import FetchAllUsers from "./admin/allUsers";
import FetchAllItems from "./admin/allItems";
import LookingFor from "./commonPages/lookingFor";
import SystemUpdates from "./commonPages/systemUpdates";
import SearchItem from "./commonPages/searchItem";

const App: React.FC = () => {
  const { adminRights } = useContext(UserContext);

  usePageViews();

  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/bikes-on-sale" element={<BikesOnSale />} />
        <Route path="/vendors-list" element={<Vendors />} />
        <Route path="/my-items-on-sale" element={<VendorItems />} />
        <Route path="/my-items-on-sale/:id" element={<VendorItemDetails />} />
        <Route path="/item-details/:id" element={<CustomerItems />} />
        <Route path="/item-add-images/:id" element={<VendorAddImages />} />
        <Route path="/gears-on-sale" element={<GearsOnSale />} />
        <Route path="/parts-on-sale" element={<PartsOnSale />} />
        <Route path="/create-an-account" element={<RegistrationPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/vendor-products/:id" element={<VendorProducts />} />
        <Route path="my-business-profile" element={<BusinessProfile />} />
        <Route path="my-sales-dashboard" element={<SalesDashboard />} />
        <Route path="looking-for" element={<LookingFor />} />
        <Route path="system-updates" element={<SystemUpdates />} />
        <Route path="item-search" element={<SearchItem />} />
        <Route
          path="/account-verification/:token"
          element={<AccountVerification />}
        />
        <Route path="/password-reset/:token" element={<PasswordReset />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/item-add-details/:id"
          element={<VendorAddItemDetails />}
        />

        {adminRights === true && (
          <>
            <Route path="/all-system-users" element={<FetchAllUsers />} />
            <Route path="/all-posted-items" element={<FetchAllItems />} />
            <Route path="/admin-dashboard" element={<Dashboard />} />
          </>
        )}
      </Routes>
    </div>
  );
};

export default App;
