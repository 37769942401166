import {
  Container,
  Row,
  Col,
  Card,
  ProgressBar,
  Table,
  Spinner,
  Badge,
} from "react-bootstrap";
import { FaMotorcycle, FaUserFriends, FaCogs, FaToolbox } from "react-icons/fa";
import Footer from "../headerpages/footer";
import AuthProvider from "../auth/authProvider";
import FetchDataCount from "../adminFetchNumbers/fetchTotalUsers";
import FetchDataCountBikes from "../adminFetchNumbers/fetchAllBikes";
import FetchDataCountGears from "../adminFetchNumbers/fetchAllGears";
import FetchDataCountParts from "../adminFetchNumbers/fetchAllParts";
import UserProgress from "../adminFetchNumbers/usersProgress";
import BusinessProfileProgress from "../adminFetchNumbers/businessProfileProgress";
import ProductProgress from "../adminFetchNumbers/productProgress";
import BusinessProfilesCount from "../adminFetchNumbers/businessProfilesCount";
import ProductsCount from "../adminFetchNumbers/productsCount";
import RegisteredUsersCount from "../adminFetchNumbers/registeredUsersCount";
import FetchDataCountRecentUsers from "../adminFetchNumbers/recentUsers";
import FetchDataCountDeletedAccounts from "../adminFetchNumbers/deletedAccounts";
import FetchDataCountNewItems from "../adminFetchNumbers/newItems";
import FetchDataCountItemsSold from "../adminFetchNumbers/itemsSold";
import FetchDataCountDeletedItems from "../adminFetchNumbers/deletedItems";
import FetchDataCountDeletedProfiles from "../adminFetchNumbers/deletedProfiles";
import FetchItemsPosted from "../admin-fetch-data/fetchItemsPosted";
import FetchNewUsers from "../admin-fetch-data/fetchNewUsers";
import CustomNavbar from "../headerpages/header";
import ViewsPerWeek from "../adminFetchNumbers/viewsPerWeek";

const Dashboard = () => {
  const newUsersCount = UserProgress();
  const businessProfile = BusinessProfileProgress();
  const productProgress = ProductProgress();

  return (
    <AuthProvider>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{
            backgroundColor: "#F5F0F0",
            borderRadius: "20px",
            padding: "30px",
          }}
        >
          <Row className="my-4">
            <Col>
              <h5
                style={{ fontWeight: "bold", textAlign: "center" }}
                className="text-danger"
              >
                Admin Dashboard
              </h5>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={3} md={6} sm={12} className="mb-4">
              <Card className="bg-primary text-white">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <FaUserFriends size={50} className="me-3" />
                    <div>
                      <Card.Title>Total Users</Card.Title>
                      <Card.Subtitle className="mb-2">
                        <h3>
                          <FetchDataCount />
                        </h3>
                      </Card.Subtitle>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={12} className="mb-4">
              <Card className="bg-success text-white">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <FaMotorcycle size={50} className="me-3" />
                    <div>
                      <Card.Title>Total Bikes</Card.Title>
                      <Card.Subtitle className="mb-2">
                        <h3>
                          <FetchDataCountBikes />
                        </h3>
                      </Card.Subtitle>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={12} className="mb-4">
              <Card className="bg-info text-white">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <FaCogs size={50} className="me-3" />
                    <div>
                      <Card.Title>Total Riding Gear</Card.Title>
                      <Card.Subtitle className="mb-2">
                        <h3>
                          <FetchDataCountGears />
                        </h3>
                      </Card.Subtitle>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={12} className="mb-4">
              <Card className="bg-secondary text-white">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <FaToolbox size={50} className="me-3" />
                    <div>
                      <Card.Title>Total Parts</Card.Title>
                      <Card.Subtitle className="mb-2">
                        <h3>
                          <FetchDataCountParts />
                        </h3>
                      </Card.Subtitle>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="spacing"></div>
          <Row className="text-center g-4 mt-4">
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Contact clicks (Last 7 days)</Card.Title>
                  <Badge pill bg="primary" className="fs-4">
                    <ViewsPerWeek />
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>New Users (Last 7 days)</Card.Title>
                  <Badge pill bg="primary" className="fs-4">
                    <FetchDataCountRecentUsers />
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Active Users</Card.Title>
                  <Badge pill bg="success" className="fs-4">
                    ...
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Deleted Accounts</Card.Title>
                  <Badge pill bg="danger" className="fs-4">
                    <FetchDataCountDeletedAccounts />
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>New Items (Last 7 days)</Card.Title>
                  <Badge pill bg="info" className="fs-4">
                    <FetchDataCountNewItems />
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="text-center g-4 mt-4">
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Sold Items</Card.Title>
                  <Badge pill bg="warning" className="fs-4">
                    <FetchDataCountItemsSold />
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Deleted Items</Card.Title>
                  <Badge pill bg="secondary" className="fs-4">
                    <FetchDataCountDeletedItems />
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Business Profiles</Card.Title>
                  <Badge pill bg="dark" className="fs-4">
                    <BusinessProfilesCount />
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Deleted Business Profiles</Card.Title>
                  <Badge pill bg="muted" className="fs-4">
                    <div style={{ color: "green" }}>
                      <FetchDataCountDeletedProfiles />
                    </div>
                  </Badge>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <div className="spacing"></div>
        <Container
          fluid
          style={{
            borderRadius: "20px",
            padding: "30px",
          }}
        ></Container>

        <Container
          fluid
          style={{
            padding: "30px",
          }}
        >
          <div className="table-responsive">
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Progress data
            </h5>
            <Table
              hover
              responsive
              style={{
                backgroundColor: "transparent",
                borderCollapse: "separate",
              }}
            >
              <thead style={{ backgroundColor: "#FFF5EE" }}>
                <tr>
                  <th>Item</th>
                  <th>Current</th>
                  <th>Target</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Registered users (Email verified)</td>
                  <td>
                    <RegisteredUsersCount />
                  </td>
                  <td>50</td>
                  <td>
                    {newUsersCount !== null ? (
                      <ProgressBar
                        now={newUsersCount}
                        label={`${newUsersCount}%`}
                      />
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}{" "}
                  </td>
                </tr>
                <tr>
                  <td>Business profiles (Active)</td>
                  <td>
                    <BusinessProfilesCount />
                  </td>
                  <td>20</td>
                  <td>
                    {businessProfile !== null ? (
                      <ProgressBar
                        now={businessProfile}
                        label={`${businessProfile}%`}
                      />
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}{" "}
                  </td>
                </tr>
                <tr>
                  <td>Posted items on sale</td>
                  <td>
                    <ProductsCount />
                  </td>
                  <td>200</td>
                  <td>
                    {productProgress !== null ? (
                      <ProgressBar
                        now={productProgress}
                        label={`${productProgress}%`}
                      />
                    ) : (
                      <Spinner animation="border" size="sm" />
                    )}{" "}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
        <div className="spacing"></div>
        <br></br>
        <FetchNewUsers />
        <div className="spacing"></div>
        <br></br>
        <FetchItemsPosted />
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default Dashboard;
