import React, { useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { Button, Card, Col, Container, Pagination, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useNavigate } from "react-router-dom";
import formatNumberWithCommas from "../components/numberFormat";
import FetchImage from "../components/fetchImage";
import { Eye } from "react-bootstrap-icons";
import CustomNavbar from "../headerpages/header";
import NoData from "../images/noData.png";

interface ProductsData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
}

const GearsOnSale: React.FC = () => {
  const [gears, setGears] = useState<ProductsData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const baseUrl = `${BASE_API_URL}`;
        const methodGet = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        // Get gears on sale
        const gearsOnSaleResponse = await fetch(
          `${baseUrl}/customer-side/get-all-gears-for-customer`,
          methodGet
        );
        const bikesOnSaleItems = await gearsOnSaleResponse.json();

        if (gearsOnSaleResponse.ok) {
          setGears(bikesOnSaleItems);
        } else {
          const { statusCode, message } = bikesOnSaleItems;
          const errorMessage = `${statusCode} - ${message}`;
          toast.error(errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProducts();
  }, []);

  const handleViewClick = (id: number) => {
    navigate(`/item-details/${id}`);
  };

  // Logic to get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = gears.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="body">
      <CustomNavbar />

      <Container style={{ backgroundColor: "#F5F0F0", padding: "40px" }} fluid>
        <Container style={{ color: "GrayText" }}>
          Home | Riding gear on sale
        </Container>
      </Container>

      {currentItems.length > 0 ? (
        <>
          <Container className="py-4">
            <h5 style={{ fontWeight: "bold" }}>Riding gear on sale</h5>
            <p>
              Explore discounted riding gear offered by our registered vendors
              below. Take advantage of these great deals!
            </p>
            <div className="spacing"></div>
            <Row>
              {currentItems.map((item, index) => (
                <Col key={index} md={3} style={{ textAlign: "center" }}>
                  <Card className="mb-4">
                    <FetchImage postId={item.id} />
                    <Card.Body>
                      <Card.Title>{item.productName}</Card.Title>
                      <p>
                        <span className="price">
                          Ksh: {formatNumberWithCommas(item.priceOfItem)}
                        </span>
                      </p>
                      <Button
                        onClick={() => handleViewClick(item.id)}
                        variant="danger"
                        size="sm"
                      >
                        View more details <Eye />
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="spacing"></div>

            <Pagination>
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev
                onClick={() =>
                  paginate(currentPage === 1 ? 1 : currentPage - 1)
                }
              />
              {[...Array(Math.ceil(gears.length / itemsPerPage))].map(
                (_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i + 1 === currentPage}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                onClick={() =>
                  paginate(
                    currentPage === Math.ceil(gears.length / itemsPerPage)
                      ? currentPage
                      : currentPage + 1
                  )
                }
              />
              <Pagination.Last
                onClick={() => paginate(Math.ceil(gears.length / itemsPerPage))}
              />
            </Pagination>
          </Container>
        </>
      ) : (
        <>
          <Container
            style={{
              marginTop: "100px",
              marginBottom: "100px",
              textAlign: "center",
            }}
          >
            <img
              src={NoData}
              style={{ borderRadius: "10px" }}
              alt="Product Images"
              className="img-fluid custom-hover-img custom-hover-col"
            />
            <h5>No riding gear on sale found</h5>
          </Container>
        </>
      )}

      <Footer />
    </div>
  );
};

export default GearsOnSale;
