// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-section-fade {
  background-color: rgba(33, 29, 29, 0.6);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 15px;
}

.login-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-page h2 {
  margin-bottom: 20px;
}

.submit-btn {
  width: 100%;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/loginPage.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,kCAA0B;UAA1B,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".login-section-fade {\n  background-color: rgba(33, 29, 29, 0.6);\n  backdrop-filter: blur(5px);\n  padding: 20px;\n  border-radius: 15px;\n}\n\n.login-page {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.login-page h2 {\n  margin-bottom: 20px;\n}\n\n.submit-btn {\n  width: 100%;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
