// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-bottom-one {
  margin-bottom: 80px;
}

.welcome-header {
  font-weight: bold;
  text-align: center;
  color: #0c0101;
}

.welcome-paragraph {
  font-size: 16px;
  line-height: 1.5;
}

.highlight {
  color: #000;
}

.spacing {
  margin: 40px 0;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  width: 100%;
  background: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/cssFiles/video.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB,EAAE,sBAAsB;EAC9C,SAAS;EACT,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".margin-bottom-one {\n  margin-bottom: 80px;\n}\n\n.welcome-header {\n  font-weight: bold;\n  text-align: center;\n  color: #0c0101;\n}\n\n.welcome-paragraph {\n  font-size: 16px;\n  line-height: 1.5;\n}\n\n.highlight {\n  color: #000;\n}\n\n.spacing {\n  margin: 40px 0;\n}\n\n.video-container {\n  position: relative;\n  padding-bottom: 56.25%; /* 16:9 aspect ratio */\n  height: 0;\n  overflow: hidden;\n  width: 100%;\n  background: #000;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n}\n\n.video-container iframe {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
