import { FormEvent, useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import Footer from "../headerpages/footer";
import NavigationPage from "../components/navigationPages";
import CustomNavbar from "../headerpages/header";

const LoginPage = () => {
  const [userEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const userData = { userEmail, password };
      const response = await fetch(`${BASE_API_URL}/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      const data = await response?.json();
      const { statusCode, message } = data;

      if (response.ok) {
        const { access_token } = data.token;
        localStorage.setItem("token", access_token);
        window.location.href = "/";
      } else {
        const errorMessage = `${statusCode} -  ${message}`;
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <CustomNavbar />

      <Container fluid style={{ backgroundColor: "#F5F0F0", padding: "40px" }}>
        <Container>
          <div style={{ textAlign: "center" }}>
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Login to access full system features
            </h5>
          </div>
        </Container>
      </Container>

      <Container className="py-5">
        <Row>
          <Col md={3} className="registration-info"></Col>
          <Col md={6} className="registration-form">
            <div className="registration-form-inner">
              <br />
              <Form onSubmit={handleSignIn}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={userEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  disabled={submitting}
                  type="submit"
                  className="w-100"
                >
                  {submitting === true ? "Signing in" : "Sign In"}
                </Button>
              </Form>
              <br />
              <br />
              <br />
              <NavigationPage />
            </div>
          </Col>
          <Col md={3} className="registration-info"></Col>
        </Row>
      </Container>
      <div className="spacing"></div>
      <div className="spacing"></div>
      <div className="spacing"></div>
      <Footer />
    </>
  );
};

export default LoginPage;
