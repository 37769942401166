import React, { useEffect, useState } from "react";
import Footer from "../headerpages/footer";
import { Container, Row, Col, Button, Card, Modal } from "react-bootstrap";
import "../cssFiles/bodyCss.css";
import "../cssFiles/product.css";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import { useParams } from "react-router-dom";
import PostView from "../components/postViewDetails";
import SellerContactsView from "../components/contactsView";
import { useNavigate } from "react-router-dom";
import formatNumberWithCommas from "../components/numberFormat";
import TimeDisplay from "../components/timeFormat";
import FetchImage from "../components/fetchImage";
import FetchDetailsImage from "../components/fetchDetailsImageCustomer";
import noData from "../images/noData.png";
import { FaFacebook } from "react-icons/fa";
import CustomNavbar from "../headerpages/header";
import GetCategoryCustomer from "../components/fetchCategoryCustomer";
import { Eye } from "react-bootstrap-icons";

interface ItemData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
  createdAt: string;
}

interface ItemDetails {
  bikeCondition: string;
  mileage: number;
  yearOfManufacture: string;
  engineSize: number;
  viewingLocation: string;
  otherDetails: string;
  gearCondition: string;
  colorsAvailable: string;
  sizesAvailable: string;
  partCondition: string;
  locationForPickUp: string;
}

interface BusinessData {
  id: number;
  businessContacts: string;
  businessEmail: string;
  businessName: string;
}

interface images {
  id: number;
  imageUrl: string;
}

const CustomerItems: React.FC = () => {
  const navigate = useNavigate();
  const [itemDetails, setItemDetails] = useState<ItemDetails[]>([]);
  const [getItem, setGetItem] = useState<ItemData | null>(null);
  const [mostViewed, setMostViewed] = useState<ItemData[]>([]);
  const [businessDetails, setBusinessDetails] = useState<BusinessData[]>([]);
  const [visibleContacts, setVisibleContacts] = useState<number | null>(null);
  const [productImages, setProductImages] = useState<images[]>([]);
  const [selectedImage, setSelectedImage] = useState("");
  const { id } = useParams<{ id: string }>();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setShow(true);
  };

  // Convert the itemId to a number
  const postId = Number(id);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const baseUrl = `${BASE_API_URL}`;
        const methodGet = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        // Get items
        const getItems = await fetch(
          `${baseUrl}/customer-side/get-item-for-customer/${postId}`,
          methodGet
        );

        if (getItems.ok) {
          const data = await getItems.json();
          setGetItem(data);
        }

        // Get item details
        const getDetails = await fetch(
          `${baseUrl}/customer-side/get-item-details-for-customer/${postId}`,
          methodGet
        );
        const dataOne = await getDetails.json();
        if (getDetails.ok) {
          setItemDetails([dataOne]);
        }

        // Get business details
        const getBusiness = await fetch(
          `${baseUrl}/customer-side/get-business-details-for-customer/${postId}`,
          methodGet
        );
        const dataTwo = await getBusiness.json();
        if (getBusiness.ok) {
          setBusinessDetails([dataTwo]);
        }

        // Get images
        const getImages = await fetch(
          `${baseUrl}/customer-side/get-product-images-customer/${postId}`,
          methodGet
        );
        const dataImage = await getImages.json();
        if (getImages.ok) {
          setProductImages(dataImage);
        }
      } catch (error) {}
    };

    fetchItemData();
  }, [postId]);

  useEffect(() => {
    const fetchVendorItemsData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/customer-side/get-item-details-for-customer/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const dataOne = await response.json();

        if (response.ok) {
          setItemDetails([dataOne]);
        }
      } catch (error) {}
    };

    fetchVendorItemsData();
  }, [postId]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/customer-side/get-similar-items-for-customer/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const productsOnSale = await response.json();

        if (response.ok) {
          setMostViewed(productsOnSale);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchProducts();
  }, [postId]);

  const handleViewClick = (id: number) => {
    navigate(`/item-details/${id}`);
    window.location.reload();
  };

  const handleButtonClick = async (postId: number) => {
    setVisibleContacts((prev) => (prev === postId ? null : postId));

    try {
      const response = await fetch(
        `${BASE_API_URL}/user-view/post-view/${postId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ postId }),
        }
      );

      if (response.ok) {
      }
    } catch (error) {
      toast.error(`Error: ${error}`);
    }
  };

  // const handleShare = async () => {
  //   try {
  //     const response = await fetch(
  //       `${BASE_API_URL}/facebook-post-share/post-to-group`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ postId }),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to share post");
  //     }

  //     const data = await response.json();
  //     toast.success("Post shared successfully!");
  //   } catch (error) {
  //     console.error("Error sharing post:", error);
  //     toast.error("Failed to share post. Please try again.");
  //   }
  // };

  return (
    <div className="body">
      <CustomNavbar />

      {getItem ? (
        <>
          {getItem ? (
            <>
              <Container
                style={{ backgroundColor: "#F5F0F0", padding: "40px" }}
                fluid
              >
                {getItem && (
                  <>
                    <Container style={{ color: "GrayText" }}>
                      Home |{" "}
                      <GetCategoryCustomer categoryId={getItem.itemCategory} />{" "}
                      | {getItem.productName} details
                    </Container>
                  </>
                )}
              </Container>
            </>
          ) : (
            <></>
          )}

          <div className="spacing"></div>
          <Container>
            {getItem && (
              <Card>
                <Row>
                  <Col md={6}>
                    <>
                      <FetchDetailsImage postId={getItem.id} />
                    </>
                  </Col>
                  <Col md={6}>
                    <Card.Body>
                      <Card.Title as="h5" className="text-danger">
                        <Row>
                          <Col md={8}>
                            <h5 style={{ fontWeight: "bold" }}>
                              {getItem.productName}
                            </h5>
                            <br></br>

                            <div className="price-section mb-3">
                              <span className="text-success">
                                {getItem.availability ? "Available" : "Sold"}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col md={4}>
                            <Button disabled size="sm" variant="primary">
                              <FaFacebook /> Share on Facebook
                            </Button>
                          </Col>
                        </Row>
                        <br></br>
                      </Card.Title>
                      <Card.Text
                        style={{
                          backgroundColor: "#F5F0F0",
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <b>Price: </b>{" "}
                        {formatNumberWithCommas(getItem.priceOfItem)}
                        <br />
                        <b>Purchase: </b>
                        {getItem.negotiable ? "Negotiable" : "Fixed price"}
                        <div className="spacing"></div>
                        <Card.Subtitle className="mb-2 text-muted">
                          <small style={{ color: "grey" }}>
                            Posted{" "}
                            <TimeDisplay timeString={getItem.createdAt} />
                          </small>
                        </Card.Subtitle>
                      </Card.Text>

                      <span>
                        {itemDetails.map((details, index) => (
                          <div key={index}>
                            <>
                              {getItem && (
                                <div
                                  style={{
                                    backgroundColor: "#F5F0F0",
                                    borderRadius: "10px",
                                    padding: "10px",
                                  }}
                                >
                                  {getItem.itemCategory === 1 && (
                                    <>
                                      <b>Condition: </b>
                                      {details.bikeCondition}
                                      <br />
                                      <b>Year of manufacture: </b>
                                      {details.yearOfManufacture}
                                      <br />
                                      <b>Engine size: </b>
                                      {details.engineSize}cc
                                      <br />
                                      <b>Mileage: </b>
                                      {details?.mileage} KMs
                                      <br />
                                      <b>Viewing location: </b>
                                      {details.viewingLocation}
                                      <div className="spacing"></div>
                                      {details.otherDetails}
                                    </>
                                  )}

                                  {getItem.itemCategory === 2 && (
                                    <>
                                      <b>Condition: </b>
                                      {details.gearCondition}
                                      <br />
                                      <b>Colors available: </b>
                                      {details.colorsAvailable}
                                      <br />
                                      <b>Sizes available: </b>
                                      {details.sizesAvailable}
                                      <br />
                                      <b>Details: </b>
                                      {details.otherDetails ||
                                        "Details not found"}
                                    </>
                                  )}

                                  {getItem.itemCategory === 3 && (
                                    <>
                                      <b>Condition: </b>
                                      {details.partCondition}
                                      <br />
                                      <b>Location for pick up: </b>
                                      {details.locationForPickUp ||
                                        "Unspecified"}
                                      <br />
                                      <b>Details: </b>
                                      {details.otherDetails ||
                                        "Details not found"}
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          </div>
                        ))}
                      </span>
                      <span className="ms-2">
                        <h5
                          className="text-danger"
                          style={{ fontWeight: "bold" }}
                        >
                          Seller contacts
                        </h5>
                        {businessDetails.map((item, index) => (
                          <div key={index}>
                            <div>
                              {visibleContacts === item.id && (
                                <>
                                  <br></br>
                                  <b>Business name: </b> {item.businessName}
                                  <br />
                                  <b>Email: </b> {item.businessEmail}
                                  <br />
                                  <b>Contacts: </b> {item.businessContacts}
                                  <br />
                                </>
                              )}
                            </div>
                            <Button
                              onClick={() => handleButtonClick(item.id)}
                              size="sm"
                              variant="outline-danger"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              {visibleContacts === item.id
                                ? "Hide Seller Contact Details"
                                : "Show Seller Contact Details"}
                            </Button>
                            <hr></hr>
                            <div className="spacing"></div>
                          </div>
                        ))}
                      </span>
                      <span className="ms-2">
                        <h5 style={{ fontWeight: "bold" }}>Other images</h5>
                        <div className="spacing"></div>
                        <Container>
                          <Row className="justify-content-center">
                            {productImages.map((item, index) => (
                              <Col
                                key={index}
                                xs={4}
                                sm={2}
                                md={2}
                                className="mb-3"
                              >
                                <img
                                  src={item.imageUrl}
                                  style={{ borderRadius: "10px" }}
                                  alt="Product"
                                  className="img-fluid custom-hover-img custom-hover-col"
                                  onClick={() => handleShow(item.imageUrl)}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Container>
                      </span>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            )}
          </Container>

          <div className="spacing"></div>
          <Container
            fluid
            className="py-4"
            style={{ backgroundColor: "#F5F0F0" }}
          >
            <Container className="py-4" style={{ backgroundColor: "#F5F0F0" }}>
              <h5 style={{ fontWeight: "bold" }}>Similar items on sale</h5>
              <Row>
                {/* Product 2 */}
                {mostViewed.map((item, index) => (
                  <Col key={index} md={3} style={{ textAlign: "center" }}>
                    <Card className="mb-4">
                      <FetchImage postId={item.id} />
                      <Card.Body>
                        <Card.Title>{item.productName}</Card.Title>
                        <p>
                          <span className="price">
                            Ksh: {formatNumberWithCommas(item.priceOfItem)}
                          </span>
                        </p>
                        <p className="card-details">
                          <span style={{ color: "#e13032" }}>
                            <Button
                              onClick={() => handleViewClick(item.id)}
                              variant="danger"
                              size="sm"
                              className="mt-3"
                            >
                              View more details <Eye />
                            </Button>
                          </span>
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </Container>
          <Container className="my-5">
            <Row className="align-items-center text-center bg-light border rounded py-5">
              <Col>
                <h3 className="mb-3">Advertise Here</h3>
                <p className="text-muted">
                  Reach tens of potential customers by placing your ad with us.
                </p>
                <Button variant="primary" size="sm">
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div className="image-wrapper">
          <img src={noData} alt="Product Images" className="img-fluid" />
        </div>
      )}
      <div className="spacing"></div>
      <PostView postId={postId} />
      <SellerContactsView postId={postId} />

      {/* Modal for Image Preview */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Product"
              className="img-fluid"
              style={{ width: "100%" }}
            />
          )}

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} size="sm">
              Close image view
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default CustomerItems;
