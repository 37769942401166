import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../constants/apiConfig";
import { toast } from "react-toastify";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FetchItemsPosted from "../components/itemsPosted";

interface Userdata {
  accountDeletion: boolean;
  accountVerification: boolean;
  id: number;
  fullName: string;
  createdAt: string;
  lastSeen: string;
  emailVerified: boolean;
}

const FetchNewUsers: React.FC = () => {
  const [recentUsers, setRecentUsers] = useState<Userdata[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_API_URL}/admin-overview/get-recent-users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setRecentUsers(data);
        }
      } catch (error) {
        toast.error(`Error fetching details: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewMoreUsers = () => {
    navigate("/all-system-users");
  };

  return (
    <>
      <div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <span>Loading users table</span>
            <Spinner animation="border" size="sm" className="ms-2" />
          </div>
        ) : (
          <Container
            fluid
            style={{
              borderRadius: "20px",
              padding: "30px",
            }}
          >
            <h5 style={{ fontWeight: "bold" }} className="text-danger">
              Registered Users
            </h5>
            <h6>This table shows the last 10 registered users</h6>
            <hr />

            <div
              className="table-responsive"
              style={{
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Table
                hover
                responsive
                style={{
                  backgroundColor: "transparent",
                  borderCollapse: "separate",
                }}
              >
                <thead>
                  <tr>
                    <th>#UserID</th>
                    <th>Registered</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Last Seen</th>
                    <th>Deleted</th>
                    <th>Verification</th>
                    <th>Items posted</th>
                  </tr>
                </thead>
                <tbody>
                  {recentUsers && recentUsers.length > 0 ? (
                    recentUsers.map((user) => (
                      <tr key={user.id}>
                        <td>#{user.id}</td>
                        <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                        <td>{user.fullName}</td>
                        <td
                          style={{
                            color: !user.emailVerified ? "red" : "black",
                          }}
                        >
                          {user.emailVerified ? "Verified" : "Unverified"}
                        </td>
                        <td>
                          {user.lastSeen
                            ? new Date(user.lastSeen).toLocaleDateString()
                            : "00:00:0000"}
                        </td>
                        <td
                          style={{
                            color:
                              user.accountDeletion === null ? "black" : "red",
                          }}
                        >
                          {user.accountDeletion === null ? "Active" : "Deleted"}
                        </td>
                        <td
                          style={{
                            color:
                              user.accountVerification === null
                                ? "black"
                                : "red",
                          }}
                        >
                          {user.accountVerification === null
                            ? "Verified"
                            : "Unverified"}
                        </td>
                        <td>
                          <FetchItemsPosted userId={user.id} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        No users found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <br />
            <br />
            <Button size="sm" variant="danger" onClick={handleViewMoreUsers}>
              View more users
            </Button>
          </Container>
        )}
      </div>
    </>
  );
};

export default FetchNewUsers;
