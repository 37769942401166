import React, { useEffect, useState } from "react";
import { Container, Button, Col, Row, Card } from "react-bootstrap";
import Footer from "../headerpages/footer";
import { toast } from "react-toastify";
import { BASE_API_URL } from "../constants/apiConfig";
import image from "../images/sportbike.png";
import { useNavigate } from "react-router-dom";
import formatNumberWithCommas from "../components/numberFormat";
import FetchImage from "../components/fetchImage";
import CustomNavbar from "../headerpages/header";
import { Eye } from "react-bootstrap-icons";

interface ProductsData {
  id: number;
  productName: string;
  itemCategory: number;
  priceOfItem: number;
  negotiable: boolean;
  availability: boolean;
}

const HomePage: React.FC = () => {
  const [bikes, setBikes] = useState<ProductsData[]>([]);
  const [gears, setGears] = useState<ProductsData[]>([]);
  const [parts, setParts] = useState<ProductsData[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const baseUrl = `${BASE_API_URL}`;
        const methodGet = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        // Get bikes on sale
        const bikesOnSaleResponse = await fetch(
          `${baseUrl}/customer-side/get-bikes-for-customer-home-page`,
          methodGet
        );
        const bikesOnSaleItems = await bikesOnSaleResponse.json();

        if (bikesOnSaleResponse.ok) {
          setBikes(bikesOnSaleItems);
        } else {
          const { statusCode, message } = bikesOnSaleItems;
          const errorMessage = `${statusCode} - ${message}`;
          toast.error(errorMessage);
        }

        // Get gears on sale
        const gearOnSaleResponse = await fetch(
          `${baseUrl}/customer-side/get-gears-for-customer-home-page`,
          methodGet
        );
        const gearOnSaleItems = await gearOnSaleResponse.json();

        if (bikesOnSaleResponse.ok) {
          setGears(gearOnSaleItems);
        } else {
          const { statusCode, message } = bikesOnSaleItems;
          const errorMessage = `${statusCode} - ${message}`;
          toast.error(errorMessage);
        }

        // Get parts on sale
        const partsOnSaleResponse = await fetch(
          `${baseUrl}/customer-side/get-parts-for-customer-home-page`,
          methodGet
        );
        const partsOnSaleItems = await partsOnSaleResponse.json();

        if (partsOnSaleResponse.ok) {
          setParts(partsOnSaleItems);
        } else {
          const { statusCode, message } = partsOnSaleItems;
          const errorMessage = `${statusCode} - ${message}`;
          toast.error(errorMessage);
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    fetchProducts();
  }, []);

  const handleViewClick = (id: number) => {
    navigate(`/item-details/${id}`);
  };

  const handleLoadMoreBikes = () => {
    navigate("/bikes-on-sale");
  };

  const handleLoadMoreGears = () => {
    navigate("/gears-on-sale");
  };

  const handleLoadMoreParts = () => {
    navigate("/parts-on-sale");
  };

  return (
    <>
      <div className="body">
        <CustomNavbar />

        <Container
          fluid
          style={{ backgroundColor: "#F5F0F0", padding: "40px" }}
        >
          <Row>
            <Col md={8} style={{ marginBottom: "15px" }}>
              <Card className="p-3">
                <Row>
                  <Col md={6} style={{ textAlign: "center" }}>
                    <img
                      src={image}
                      alt="Motorcycle"
                      className="img-fluid"
                      style={{ borderRadius: "15px" }}
                    />

                    <div className="spacing"></div>
                  </Col>
                  <Col md={6} className="d-flex flex-column">
                    <h5 className="welcome-header">Welcome to AMD.BIKE</h5>
                    <br></br>
                    <p className="welcome-paragraph">
                      African Motorcycle Diaries (AMD) is Kenya's largest and
                      most vibrant online motorbike community, where adventure
                      stories, thrilling experiences, and fun come to life!
                      <div className="spacing"></div>
                      Now, we're excited to introduce our comprehensive
                      marketplace, connecting bikers, buyers, and sellers on one
                      seamless platform. <b className="highlight">
                        AMD.Bike
                      </b>{" "}
                      is your ultimate destination for all things bike-related.
                      <div className="spacing"></div>
                      To have your post posted on our{" "}
                      <a
                        href="https://www.facebook.com/groups/amd.bike"
                        target="blank"
                        className="text-danger"
                        style={{ fontWeight: "bold", textDecoration: "none" }}
                      >
                        Facebook group
                      </a>{" "}
                      any day of the week, advertise on our site. Join us and
                      experience the best of the biking world in one place!
                    </p>
                    <Button
                      variant="danger"
                      onClick={handleLoadMoreBikes}
                      className="mt-3"
                    >
                      SHOP FOR A BIKE
                    </Button>
                  </Col>
                </Row>
              </Card>

              <div className="spacing"></div>
            </Col>

            <Col md={4}>
              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Body className="text-center justify-content-center">
                      <h5 className="welcome-header">
                        How to register and create a business profile
                      </h5>
                      <div className="spacing"></div>
                      <div className="video-container">
                        <iframe
                          width="560"
                          height="315"
                          loading="lazy"
                          src="https://www.youtube.com/embed/La6XOscrIqU"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>

                      <div className="spacing"></div>
                      <br />
                      <br />
                      <br />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <div className="spacing"></div>
        <div className="spacing"></div>

        {bikes.length > 0 ? (
          <Container className="py-4">
            <h5 style={{ fontWeight: "bold" }}>Sport Bikes on sale</h5>
            <p>
              Explore a variety of bikes sold by our registered vendors below.
              Take advantage of these great deals!
            </p>
            <Row>
              {/* Product 1 */}
              {bikes.map((item, index) => (
                <Col key={index} md={3} style={{ textAlign: "center" }}>
                  <Card className="mb-4">
                    <FetchImage postId={item.id} />
                    <Card.Body>
                      <Card.Title>{item.productName}</Card.Title>
                      <p>
                        <span className="price">
                          Ksh: {formatNumberWithCommas(item.priceOfItem)}
                        </span>
                      </p>
                      <Button
                        onClick={() => handleViewClick(item.id)}
                        variant="danger"
                        size="sm"
                      >
                        View more details <Eye />
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="spacing"></div>
            <Row>
              <Col md={4}>
                <Button
                  size="sm"
                  variant="outline-dark"
                  onClick={handleLoadMoreBikes}
                  className="mt-3"
                >
                  view more bikes on sale <Eye />
                </Button>
              </Col>
              <Col md={8}></Col>
            </Row>
          </Container>
        ) : (
          <></>
        )}

        {gears.length > 0 ? (
          <>
            <Container
              fluid
              className="py-4"
              style={{ backgroundColor: "#F5F0F0" }}
            >
              <Container
                className="py-4"
                style={{ backgroundColor: "#F5F0F0" }}
              >
                <h5 style={{ fontWeight: "bold" }}>Riding gear on sale</h5>
                <p>
                  Explore discounted riding gear offered by our registered
                  vendors below. Take advantage of these great deals!
                </p>
                <Row>
                  {/* Product 2 */}
                  {gears.map((item, index) => (
                    <Col key={index} md={3} style={{ textAlign: "center" }}>
                      <Card className="mb-4">
                        <FetchImage postId={item.id} />
                        <Card.Body>
                          <Card.Title>{item.productName}</Card.Title>
                          <p>
                            <span className="price">
                              Ksh: {formatNumberWithCommas(item.priceOfItem)}
                            </span>
                          </p>
                          <p className="card-details">
                            <span style={{ color: "#e13032" }}>
                              <Button
                                onClick={() => handleViewClick(item.id)}
                                variant="danger"
                                size="sm"
                                className="mt-3"
                              >
                                View more details <Eye />
                              </Button>
                            </span>
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <div className="spacing"></div>
                <Row>
                  <Col md={4}>
                    <Button
                      size="sm"
                      variant="outline-dark"
                      onClick={handleLoadMoreGears}
                      className="mt-3"
                    >
                      view more riding gear on sale <Eye />
                    </Button>
                  </Col>
                  <Col md={8}></Col>
                </Row>
              </Container>
            </Container>
          </>
        ) : (
          <></>
        )}

        {parts.length > 0 ? (
          <Container className="py-4">
            <h5 style={{ fontWeight: "bold" }}>Parts on Sale</h5>
            <p>
              Discover discounted parts offered by our registered vendors below.
              Take advantage of these great deals!
            </p>
            <Row>
              {/* Product 3 */}
              {parts.map((item, index) => (
                <Col key={index} md={3} style={{ textAlign: "center" }}>
                  <Card className="mb-4">
                    <FetchImage postId={item.id} />
                    <Card.Body>
                      <Card.Title>{item.productName}</Card.Title>
                      <p>
                        <span className="price">
                          Ksh: {formatNumberWithCommas(item.priceOfItem)}
                        </span>
                      </p>
                      <p className="card-details">
                        <span style={{ color: "#e13032" }}>
                          <Button
                            onClick={() => handleViewClick(item.id)}
                            variant="danger"
                            size="sm"
                            className="mt-3"
                          >
                            View more details <Eye />
                          </Button>
                        </span>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="spacing"></div>
            <Row>
              <Col md={4}>
                <Button
                  size="sm"
                  variant="outline-dark"
                  onClick={handleLoadMoreParts}
                  className="mt-3"
                >
                  view more bike parts on sale <Eye />
                </Button>
              </Col>
              <Col md={8}></Col>
            </Row>
          </Container>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
